@font-face {
    font-family: "Neue";
    src: url("../public/fonts/PPNeueMontreal-Medium.otf");
}

body {
    margin: 0;
    padding: 0;
    font-family: "Neue";
}

@tailwind base;
@tailwind components;
@tailwind utilities;
